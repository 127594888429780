import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Cartesian3 } from 'angular-cesium';
import { produce } from 'immer';
import { GcpBlockResponse } from '../../../generated/mms/model/gcpBlockResponse';
import { CloudFrontPreSignedPolicy } from '../../../generated/tenant/model/cloudFrontPreSignedPolicy';
import { MapStyle } from '../../shared/utils/cesium-common';
import { Cartographic, Rectangle } from '../../shared/utils/geo';
import { convertToMeters } from '../../shared/utils/unit-conversion';
import { Site } from '../../tenant/tenant.model';
import { GCPItem, Mark } from '../../upload-wizard/gcp-marking/state/gcp.model';
import { ContourOptions, DEFAULT_ELEVATION_RAMP_COLORS, ElevationOptions } from '../site-map/map-overlays/elevation-contour.service';
import { Image, MapTextureType, OverlaysEnum, TabEnum, Task } from './detailed-site.model';
import { GCP_PIN_ID_PREFIX } from './detailed-site.utils';

export interface TaskState extends EntityState<Task>, ActiveState {}

export interface DetailedSiteState {
  site?: Site;
  siteBounds: Rectangle;
  viewerUrlsCredentials?: CloudFrontPreSignedPolicy;
  ui: {
    activeTab: TabEnum;
    tabsExpandedGroups: { [key in TabEnum]?: Set<string> };
    isSimpleView: boolean;
    mapPositionPin: Cartesian3;
    myPositionPin: Cartesian3;
    goToPin: Cartesian3;
    cursorLocation: Cartographic;
    imageViewerTool: { show: boolean; images: Image[] };
    siteImageryLayer: any;
    mapDiffTask: Task;
    isMapFullScreen: boolean;
    mapTexture: MapTextureType;
    dsmAvailable: boolean;
    mapLoading: boolean;
    isScreenshotGenerating: boolean;
    flightWizardDialogId: string;
    overlays: {
      [OverlaysEnum.GCPS]: {
        show: boolean;
        taskId: string;
        gcps: GCPItem[];
      };
      [OverlaysEnum.CONTOUR]: {
        show: boolean;
        generationInProgress: boolean;
        options: ContourOptions;
      };
      [OverlaysEnum.ELEVATION]: {
        show: boolean;
        options: ElevationOptions;
      };
    };
  };
  mapOpacity: number;
  mapStyle: MapStyle;
}

export function createInitialState(): DetailedSiteState {
  return {
    site: null,
    siteBounds: null,
    viewerUrlsCredentials: null,
    ui: {
      activeTab: TabEnum.OVERVIEW,
      tabsExpandedGroups: {},
      isSimpleView: false,
      mapTexture: MapTextureType.TERRAIN,
      dsmAvailable: false,
      mapPositionPin: null,
      myPositionPin: null,
      goToPin: null,
      cursorLocation: null,
      imageViewerTool: { show: false, images: null },
      siteImageryLayer: null,
      mapDiffTask: null,
      isMapFullScreen: false,
      mapLoading: true,
      isScreenshotGenerating: false,
      flightWizardDialogId: null,
      overlays: {
        [OverlaysEnum.GCPS]: {
          show: false,
          taskId: null,
          gcps: []
        },
        [OverlaysEnum.CONTOUR]: {
          show: false,
          generationInProgress: false,
          options: {
            opacity: 1.0,
            majorSpacing: null,
            minorSpacing: null
          }
        },
        [OverlaysEnum.ELEVATION]: {
          show: false,
          options: {
            opacity: 1.0,
            elevationRamp: DEFAULT_ELEVATION_RAMP_COLORS,
            maxHeight: 100,
            minHeight: 0
          }
        }
      }
    },
    mapOpacity: 100,
    mapStyle: MapStyle.STREET
  };
}

function deepFreezeFn(state: DetailedSiteState) {
  return state;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'detailed-site', deepFreezeFn })
export class DetailedSiteStore extends EntityStore<DetailedSiteState> {
  tasks = new EntityStore<TaskState>({ active: null }, { name: 'site-tasks' });

  constructor() {
    super(createInitialState());
  }

  updateMapTexture(mapTextureType: MapTextureType) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.mapTexture = mapTextureType;
      })
    );
  }

  updateDSMAvailable(available: boolean) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.dsmAvailable = available;
      })
    );
  }

  setSite(site: Site) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.site = site;
      })
    );
  }

  updateSite(site: Partial<Site>) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.site = { ...draftState.site, ...site };
      })
    );
  }

  updateSiteBounds(siteBounds: Rectangle) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.siteBounds = siteBounds;
      })
    );
  }

  setMapLoading(mapLoading: boolean) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.mapLoading = mapLoading;
      })
    );
  }

  updateMapDiffTask(task: Task) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.mapDiffTask = task;
      })
    );
  }

  setIsMapFullScreen(isFullScreen: boolean) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.isMapFullScreen = isFullScreen;
      })
    );
  }

  updateSiteImageryLayer(imageryLayer: any) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.siteImageryLayer = imageryLayer;
      })
    );
  }

  toggleImageViewerShow(show: boolean) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.imageViewerTool = { show, images: null };
      })
    );
  }

  updateImageViewerImages(images: Image[]) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.imageViewerTool = { ...draftState.ui.imageViewerTool, images };
      })
    );
  }

  updateMapPositionPin(position: Cartesian3) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.mapPositionPin = position;
      })
    );
  }

  updateMyPositionPin(position: Cartesian3) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.myPositionPin = position;
      })
    );
  }

  updateGoToPin(position: Cartesian3) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.goToPin = position;
      })
    );
  }

  updateCursorLocation(position: Cartographic) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.cursorLocation = position;
      })
    );
  }

  setTaskImages(taskId: string, images: Image[]) {
    this.tasks.update(taskId, { images });
  }

  reset() {
    super.reset();
    this.tasks.reset();
  }

  upsertTasks(tasks: Task[]) {
    this.tasks.upsertMany(tasks);
  }

  updateTask(task: Partial<Task>) {
    this.tasks.update(task.id, task);
  }

  deleteTask(taskId: string) {
    this.tasks.remove(taskId);
  }

  addTask(task: Task) {
    this.tasks.add(task);
  }

  showOverlay(overlay: OverlaysEnum, show: boolean) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.overlays[overlay].show = show;
      })
    );
  }

  setGCPOverlay(taskId: string, gcpList: GcpBlockResponse[]) {
    const siteUnits = this.getValue().site.units;
    const gcps = gcpList.map(gcp => {
      let marks = {};
      if (gcp.marks) {
        marks = gcp.marks.reduce((sum, mark) => {
          sum[mark.imageId] = { x: mark.x, y: mark.y };
          return sum;
        }, {} as Record<string, Mark>);
      }

      return {
        id: `${GCP_PIN_ID_PREFIX}${gcp.id}`,
        name: gcp.name,
        type: gcp.gcpType || 'GCP',
        x: gcp.x,
        y: gcp.y,
        z: gcp.z,
        estimatedX: gcp.estimatedX,
        estimatedY: gcp.estimatedY,
        estimatedZ: gcp.estimatedZ,
        marks,
        longitude: gcp.longitude,
        latitude: gcp.latitude,
        longitudeOri: gcp.longitude,
        latitudeOri: gcp.latitude,
        altitude: convertToMeters(gcp.z, siteUnits),
        hints: {}
      };
    });

    this.updateGCPOverlay(taskId, gcps);
  }

  updateGCPOverlay(taskId: string, gcps: GCPItem[]) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.overlays[OverlaysEnum.GCPS] = {
          ...draftState.ui.overlays[OverlaysEnum.GCPS],
          gcps,
          taskId
        };
      })
    );
  }

  setViewerCredentials(viewerCredentials: CloudFrontPreSignedPolicy) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.viewerUrlsCredentials = viewerCredentials;
      })
    );
  }

  setTerrainHeightOffset(offset: number) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.site.siteOffset = offset;
      })
    );
  }

  updateElevationOverlay(options: ElevationOptions) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.overlays[OverlaysEnum.ELEVATION].options = {
          ...draftState.ui.overlays[OverlaysEnum.ELEVATION].options,
          ...options
        };
      })
    );
  }

  updateContourOverlay(options: ContourOptions) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.overlays[OverlaysEnum.CONTOUR].options = {
          ...draftState.ui.overlays[OverlaysEnum.CONTOUR].options,
          ...options
        };
      })
    );
  }

  setContourGenerationInProgress(inProgress: boolean) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.overlays[OverlaysEnum.CONTOUR].generationInProgress = inProgress;
      })
    );
  }

  setIsSimpleView(isSimpleView: boolean) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.isSimpleView = isSimpleView;
      })
    );
  }

  setIsScreenshotGenerating(isScreenshotGenerating: boolean) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.isScreenshotGenerating = isScreenshotGenerating;
      })
    );
  }

  setFlightWizardDialogId(dialogId: string) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.flightWizardDialogId = dialogId;
      })
    );
  }

  setMapOpacity(mapOpacity: number) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.mapOpacity = mapOpacity;
      })
    );
  }

  setMapStyle(mapStyle: MapStyle) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.mapStyle = mapStyle;
      })
    );
  }

  setActiveTab(tab: TabEnum) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.activeTab = tab;
      })
    );
  }

  setTabExpandedGroups(tab: TabEnum, groupId: string, isExpanded: boolean) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        const expandedGroups = new Set(draftState.ui.tabsExpandedGroups[tab]);
        if (isExpanded) {
          expandedGroups.add(groupId);
        } else {
          expandedGroups.delete(groupId);
        }
        draftState.ui.tabsExpandedGroups[tab] = expandedGroups;
      })
    );
  }

  clearTabExpandedGroups(tab: TabEnum) {
    this.update(
      produce((draftState: DetailedSiteState) => {
        draftState.ui.tabsExpandedGroups[tab] = new Set();
      })
    );
  }
}
