/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.2.8
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ColorsPaletteModel } from './colorsPaletteModel';
import { GridColorsModel } from './gridColorsModel';
import { ReportAreaPolygonModel } from './reportAreaPolygonModel';
import { SurfaceModel } from './surfaceModel';

export interface VolumeGridReportModel {
  approvedBy?: string;
  baseSurface?: SurfaceModel;
  colorsPalette?: Array<ColorsPaletteModel>;
  colorsPaletteType?: VolumeGridReportModel.ColorsPaletteTypeEnum;
  comments?: string;
  comparisonSurface?: SurfaceModel;
  consultantLogoURL?: string;
  creationTime?: Date;
  customerLogoURL?: string;
  factorCut?: number;
  factorFill?: number;
  gridAzimuth?: number;
  gridCellHeight?: number;
  gridCellWidth?: number;
  gridColors?: GridColorsModel;
  gridReportType?: VolumeGridReportModel.GridReportTypeEnum;
  heatMapInterval?: number;
  heatMapTransparencyLevel?: number;
  heatmapMaxRange?: number;
  heatmapMinRange?: number;
  id?: string;
  lastModifiedTime?: Date;
  name?: string;
  orthoPhotoTaskId?: string;
  reportAreaPolygon?: Array<ReportAreaPolygonModel>;
  signatureLogoURL?: string;
  siteId?: string;
  smoothHeatMapEdges?: boolean;
  state?: VolumeGridReportModel.StateEnum;
  taskSurfaceType?: VolumeGridReportModel.TaskSurfaceTypeEnum;
}
export namespace VolumeGridReportModel {
  export type ColorsPaletteTypeEnum = 'NORMAL' | 'ABSOLUTE' | 'EVEN_SPACE_FROM_ZERO';
  export const ColorsPaletteTypeEnum = {
    NORMAL: 'NORMAL' as ColorsPaletteTypeEnum,
    ABSOLUTE: 'ABSOLUTE' as ColorsPaletteTypeEnum,
    EVENSPACEFROMZERO: 'EVEN_SPACE_FROM_ZERO' as ColorsPaletteTypeEnum
  };
  export type GridReportTypeEnum = 'VGHR' | 'EGHR';
  export const GridReportTypeEnum = {
    VGHR: 'VGHR' as GridReportTypeEnum,
    EGHR: 'EGHR' as GridReportTypeEnum
  };
  export type StateEnum = 'PROCESSING' | 'READY' | 'FAILED';
  export const StateEnum = {
    PROCESSING: 'PROCESSING' as StateEnum,
    READY: 'READY' as StateEnum,
    FAILED: 'FAILED' as StateEnum
  };
  export type TaskSurfaceTypeEnum = 'DTM' | 'DSM';
  export const TaskSurfaceTypeEnum = {
    DTM: 'DTM' as TaskSurfaceTypeEnum,
    DSM: 'DSM' as TaskSurfaceTypeEnum
  };
}
