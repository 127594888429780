import { Cartesian3 } from 'angular-cesium';
import { groupBy } from 'lodash';
import { CloudFrontPreSignedPolicy } from '../../../generated/tenant/model/cloudFrontPreSignedPolicy';
import PERMISSIONS from '../../auth/state/permissions';
import { UnitsEnum } from '../../shared/utils/unit-conversion';
import { ColorRampStop, DEFAULT_ELEVATION_RAMP_COLORS } from '../site-map/map-overlays/elevation-contour.service';
import {
  AnalyticType,
  AnnotationType,
  DataValue,
  EntityType,
  MapEntity,
  MeasurementType,
  ModelEditType
} from './detailed-site-entities/detailed-site-entities.model';
import { Task, TaskOrDesign } from './detailed-site.model';

interface EntityWithPositions {
  positions?: Cartesian3[];
}

export interface DeltaElevationVizOptions {
  deltaHeightsRange: Record<string, { min: number; max: number }>;
  elevationRamp?: ColorRampStop[];
  opacity?: number; // 0 - 1.0;
}

export const DEFAULT_VIZ_OPTIONS: { [type in EntityType]?: DeltaElevationVizOptions } = Object.freeze({
  [EntityType.POLYGON_DELTA_ELEVATION]: {
    deltaHeightsRange: {},
    elevationRamp: DEFAULT_ELEVATION_RAMP_COLORS,
    opacity: 0.5
  } as DeltaElevationVizOptions
});

let idsCount = 0;
const TMP_PREFIX = `temp-`;
export function isNewEntity(id: string) {
  return id?.startsWith(TMP_PREFIX);
}

export function generateTempEntityId() {
  return `${TMP_PREFIX}${++idsCount}`;
}

export function positionsChanged(before: EntityWithPositions, after: EntityWithPositions) {
  if (!before || !after) {
    // Don't notify on entity deletion or creation
    return false;
  }

  if (before.positions.length !== after.positions.length) {
    return true;
  }

  for (let i = 0; i < before.positions.length; i++) {
    const beforePos = before.positions[i];
    const afterPos = after.positions[i];
    if (beforePos.x !== afterPos.x || beforePos.y !== afterPos.y) {
      return true;
    }
  }

  return false;
}

export const MEASUREMENT_ICONS = Object.freeze({
  [MeasurementType.POINT]: 'measure-point',
  [AnalyticType.POINT_DELTA_ELEVATION]: 'delta-elevation',
  [AnnotationType.ANNOTATION]: 'annotations',
  [MeasurementType.DISTANCE]: 'line-horizontal',
  [MeasurementType.ANGLE]: 'angle',
  [MeasurementType.CROSS_SECTION]: 'cross-section',
  [AnalyticType.CROSS_SECTION_PROGRESS]: 'cross-section-progress',
  [MeasurementType.AREA]: 'measure-area',
  [MeasurementType.VOLUME]: 'volume',
  [AnalyticType.POLYGON_DELTA_ELEVATION]: 'delta-elevation',
  [AnalyticType.POLYGON_DELTA_VOLUME]: 'volume-elevation'
});

export function groupValuesByUnit<T extends { units: string; unitExp: number }>(list: T[]): T[][] {
  // Save groups original order
  const keyGroups = Array.from(new Set(list.sort((o1, o2) => o2.unitExp - o1.unitExp).map(o => o.units)));

  // Group by key
  const valuesByKey = groupBy(list, 'units');

  // Return data by keys in original order
  return keyGroups.map(k => valuesByKey[k]);
}

export interface CalcField {
  /** Field key in mapping */
  key: string;
  /** Field display name */
  field: string;
  /** Field units */
  units: UnitsEnum;
  /** Field unit's exponent */
  unitExp: number;
  /** Field color in UI */
  color?: string;
  /** Field tooltip */
  tooltip?: string;
}

export const FIELD_MAPPING = Object.freeze<{ [key: string]: CalcField }>({
  elevationMin: {
    key: 'elevationMin',
    field: $localize`:@@detailedSite.entityFieldNameElevationMin:Min elevation`,
    units: UnitsEnum.METER,
    unitExp: 1,
    color: '#0501ae',
    tooltip: $localize`:@@detailedSite.entityFieldNameElevationMinTooltip:The lowest elevation value as measured on the model's surface`
  },
  elevationMax: {
    key: 'elevationMax',
    field: $localize`:@@detailedSite.entityFieldNameElevationMax:Max elevation`,
    units: UnitsEnum.METER,
    unitExp: 1,
    color: '#ff1100',
    tooltip: $localize`:@@detailedSite.entityFieldNameElevationMaxTooltip:The highest elevation value as measured on the model's surface`
  },
  elevationDeltaMin: {
    key: 'elevationDeltaMin',
    field: $localize`:@@detailedSite.entityFieldNameElevationDeltaMin:Min delta elevation`,
    units: UnitsEnum.METER,
    unitExp: 1,
    color: '#0501ae',
    tooltip: $localize`:@@detailedSite.entityFieldNameElevationDeltaMinTooltip:The lowest delta elevation value as measured on the model's surface`
  },
  elevationDeltaMax: {
    key: 'elevationDeltaMax',
    field: $localize`:@@detailedSite.entityFieldNameElevationDeltaMax:Max delta elevation`,
    units: UnitsEnum.METER,
    unitExp: 1,
    color: '#ff1100',
    tooltip: $localize`:@@detailedSite.entityFieldNameElevationDeltaMaxTooltip:The highest delta elevation value as measured on the model's surface`
  },
  elevation: {
    key: 'elevation',
    field: $localize`:@@detailedSite.entityFieldNameElevation:Point elevation`,
    units: UnitsEnum.METER,
    unitExp: 1,
    color: '#2a1c50',
    tooltip: $localize`:@@detailedSite.entityFieldNameElevationTooltip:Point elevation as measured on the model's surface`
  },
  cut: {
    key: 'cut',
    field: $localize`:@@detailedSite.entityFieldNameCut:Cut volume`,
    units: UnitsEnum.METER,
    unitExp: 3,
    color: '#db6262',
    tooltip: $localize`:@@detailedSite.entityFieldNameCutTooltip:The combined volumes measured on the model that extend above the target surface or descend below the base surface`
  },
  cutHorizontalArea: {
    key: 'cutHorizontalArea',
    field: $localize`:@@detailedSite.entityFieldNameCutHorizontalArea:Cut horizontal area`,
    units: UnitsEnum.METER,
    unitExp: 2,
    tooltip: $localize`:@@detailedSite.entityFieldNameCutHorizontalAreaTooltip:Area as measured by the cut polygon(s), without elevation`
  },
  net: {
    key: 'net',
    field: $localize`:@@detailedSite.entityFieldNameNet:Net volume`,
    units: UnitsEnum.METER,
    unitExp: 3,
    color: '#5371db',
    tooltip: $localize`:@@detailedSite.entityFieldNameNetTooltip:The difference in volume between areas filled and areas excavated`
  },
  fill: {
    key: 'fill',
    field: $localize`:@@detailedSite.entityFieldNameFill:Fill volume`,
    units: UnitsEnum.METER,
    unitExp: 3,
    color: '#8fd670',
    tooltip: $localize`:@@detailedSite.entityFieldNameFillTooltip:The combined volumes measured on the model that descend below the target surface or extend above the base surface`
  },
  fillHorizontalArea: {
    key: 'fillHorizontalArea',
    field: $localize`:@@detailedSite.entityFieldNameFillHorizontalArea:Fill horizontal area`,
    units: UnitsEnum.METER,
    unitExp: 2,
    tooltip: $localize`:@@detailedSite.entityFieldNameFillHorizontalAreaTooltip:Area as measured by the fill polygon(s), without elevation`
  },
  surfaceArea: {
    key: 'surfaceArea',
    field: $localize`:@@detailedSite.entityFieldNameSurfaceArea:Surface area`,
    units: UnitsEnum.METER,
    unitExp: 2,
    color: '#f17a55',
    tooltip: $localize`:@@detailedSite.entityFieldNameSurfaceAreaTooltip:Area as measured on the model`
  },
  horizontalArea: {
    key: 'horizontalArea',
    field: $localize`:@@detailedSite.entityFieldNameHorizontalArea:Horizontal area`,
    units: UnitsEnum.METER,
    unitExp: 2,
    tooltip: $localize`:@@detailedSite.entityFieldNameHorizontalAreaTooltip:Area as measured by the marked polygon, without elevation`
  },
  angle1: {
    key: 'angle1',
    field: $localize`:@@detailedSite.entityFieldNameAngle1:∡A`,
    units: UnitsEnum.DEGREE,
    unitExp: 1
  },
  angle2: {
    key: 'angle2',
    field: $localize`:@@detailedSite.entityFieldNameAngle2:∡B`,
    units: UnitsEnum.DEGREE,
    unitExp: 1
  },
  angle3: {
    key: 'angle3',
    field: $localize`:@@detailedSite.entityFieldNameAngle3:∡C`,
    units: UnitsEnum.DEGREE,
    unitExp: 1
  },
  distance1: {
    key: 'distance1',
    field: $localize`:@@detailedSite.entityFieldNameDistance1:Horizontal Distance AB`,
    units: UnitsEnum.METER,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameDistance1Tooltip:The distance between the marked vertices without elevation`
  },
  distance2: {
    key: 'distance2',
    field: $localize`:@@detailedSite.entityFieldNameDistance2:Horizontal Distance BC`,
    units: UnitsEnum.METER,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameDistance1Tooltip:The distance between the marked vertices without elevation`
  },
  distance3: {
    key: 'distance3',
    field: $localize`:@@detailedSite.entityFieldNameDistance3:Horizontal Distance CA`,
    units: UnitsEnum.METER,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameDistance1Tooltip:The distance between the marked vertices without elevation`
  },
  circumcircleRadius: {
    key: 'circumcircleRadius',
    field: $localize`:@@detailedSite.entityFieldNameCircumcircleRadius:Circumcircle Radius`,
    units: UnitsEnum.METER,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameCircumcircleRadiusTooltip:The radius of the circle passing through the marked vertices. The calculation uses horizontal distances.`
  },
  slopeDistance: {
    key: 'slopeDistance',
    field: $localize`:@@detailedSite.entityFieldNameSlopeDistance:Slope distance`,
    units: UnitsEnum.METER,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameSlopeDistanceTooltip:Distance of the marked polyline with elevation`
  },
  areaSurfacePerimeter: {
    key: 'areaSurfacePerimeter',
    field: $localize`:@@detailedSite.entityFieldNameAreaSurfacePerimeter:Surface perimeter`,
    units: UnitsEnum.METER,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameAreaSurfacePerimeterTooltip:The polygon's perimeter as measured on the model`
  },
  areaHorizontalPerimeter: {
    key: 'areaHorizontalPerimeter',
    field: $localize`:@@detailedSite.entityFieldNameAreaHorizontalPerimeter:Horizontal perimeter`,
    units: UnitsEnum.METER,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameAreaHorizontalPerimeterTooltip:The polygon's perimeter as measured without elevation`
  },
  surfaceDistance: {
    key: 'surfaceDistance',
    field: $localize`:@@detailedSite.entityFieldNameSurfaceDistance:Surface distance`,
    units: UnitsEnum.METER,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameSurfaceDistanceTooltip:Distance as measured on the model's surface`
  },
  horizontalDistance: {
    key: 'horizontalDistance',
    field: $localize`:@@detailedSite.entityFieldNameHorizontalDistance:Horizontal distance`,
    units: UnitsEnum.METER,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameHorizontalDistanceTooltip:Distance of the marked polyline without elevation`
  },
  slopePercent: {
    key: 'slopePercent',
    field: $localize`:@@detailedSite.entityFieldNameSlopePercent:Slope`,
    units: UnitsEnum.PERCENT,
    unitExp: 1,
    tooltip: $localize`:@@detailedSite.entityFieldNameSlopePercentTooltip:The vertical change (rise) divided by the horizontal change (run) multiplied by 100.\nA low value means a gentle slope, high value means a steep slope and a value of 100% means an exact 45° slope`
  }
});

export const DELTA_FIELDS = Object.freeze<{ [key in AnalyticType]?: string[] }>({
  [AnalyticType.POINT_DELTA_ELEVATION]: [FIELD_MAPPING.elevation.key],
  [AnalyticType.POLYGON_DELTA_ELEVATION]: [
    FIELD_MAPPING.elevationDeltaMin.key,
    FIELD_MAPPING.elevationDeltaMax.key,
    FIELD_MAPPING.surfaceArea.key
  ],
  [AnalyticType.POLYGON_DELTA_VOLUME]: [
    FIELD_MAPPING.fill.key,
    FIELD_MAPPING.net.key,
    FIELD_MAPPING.cut.key,
    FIELD_MAPPING.elevationMin.key,
    FIELD_MAPPING.elevationMax.key
  ]
});

export function getDataValue(key: string, value: number): DataValue {
  if (key in FIELD_MAPPING) {
    return {
      ...FIELD_MAPPING[key],
      value
    };
  }

  return {
    key,
    field: key,
    value,
    units: UnitsEnum.METER,
    unitExp: 1
  };
}

export function sortCalcResultFields(type: EntityType) {
  let order: string[];
  switch (type) {
    case MeasurementType.DISTANCE:
      order = [
        FIELD_MAPPING.surfaceDistance.key,
        FIELD_MAPPING.slopeDistance.key,
        FIELD_MAPPING.horizontalDistance.key,
        FIELD_MAPPING.elevationMin.key,
        FIELD_MAPPING.elevationMax.key,
        FIELD_MAPPING.slopePercent.key
      ];
      break;

    case MeasurementType.AREA:
      order = [
        FIELD_MAPPING.surfaceArea.key,
        FIELD_MAPPING.horizontalArea.key,
        FIELD_MAPPING.areaSurfacePerimeter.key,
        FIELD_MAPPING.areaHorizontalPerimeter.key
      ];
      break;

    case AnalyticType.POLYGON_DELTA_VOLUME:
      order = [
        FIELD_MAPPING.cut.key,
        FIELD_MAPPING.fill.key,
        FIELD_MAPPING.net.key,
        FIELD_MAPPING.horizontalArea.key,
        FIELD_MAPPING.elevationMin.key,
        FIELD_MAPPING.elevationMax.key
      ];
      break;

    case MeasurementType.VOLUME:
      order = [
        FIELD_MAPPING.cut.key,
        FIELD_MAPPING.fill.key,
        FIELD_MAPPING.net.key,
        FIELD_MAPPING.cutHorizontalArea.key,
        FIELD_MAPPING.fillHorizontalArea.key,
        FIELD_MAPPING.horizontalArea.key,
        FIELD_MAPPING.elevationMin.key,
        FIELD_MAPPING.elevationMax.key
      ];
      break;

    case AnalyticType.POLYGON_DELTA_ELEVATION:
      order = [
        FIELD_MAPPING.surfaceArea.key,
        FIELD_MAPPING.horizontalArea.key,
        FIELD_MAPPING.elevationMin.key,
        FIELD_MAPPING.elevationMax.key,
        FIELD_MAPPING.elevationDeltaMin.key,
        FIELD_MAPPING.elevationDeltaMax.key
      ];
      break;

    case MeasurementType.ANGLE:
      order = [
        FIELD_MAPPING.distance1.key,
        FIELD_MAPPING.distance2.key,
        FIELD_MAPPING.distance3.key,
        FIELD_MAPPING.circumcircleRadius.key,
        FIELD_MAPPING.angle1.key,
        FIELD_MAPPING.angle2.key,
        FIELD_MAPPING.angle3.key
      ];
      break;

    default:
      break;
  }

  // Don't change order
  if (!order) {
    return () => null;
  }

  return (a: DataValue, b: DataValue) => order.indexOf(a.key) - order.indexOf(b.key);
}

const BASE_VERSION = 1;
const DATA_VERSIONS: Partial<Record<EntityType, number>> = Object.freeze({
  [MeasurementType.ANGLE]: 3,
  [MeasurementType.AREA]: 2,
  [MeasurementType.DISTANCE]: 8,
  [MeasurementType.VOLUME]: 3,
  [AnalyticType.POLYGON_DELTA_VOLUME]: 3,
  [AnalyticType.POLYGON_DELTA_ELEVATION]: 7,
  [MeasurementType.CROSS_SECTION]: 8,
  [AnalyticType.CROSS_SECTION_PROGRESS]: 8,
  [ModelEditType.FILTER]: 2
});
export function getLatestDataVersion(type: EntityType) {
  return DATA_VERSIONS[type] || BASE_VERSION;
}

export function isLatestDataVersion({ type, dataVersion }: { type: EntityType; dataVersion?: number }) {
  return dataVersion && dataVersion >= getLatestDataVersion(type);
}

export function getTaskOrDesignType(taskOrDesign: TaskOrDesign) {
  return (taskOrDesign as Task).missionFlightDate ? 'TASK' : 'DESIGN';
}

export function entityTypeName(entity: Partial<MapEntity>) {
  if (entity.type in MeasurementType) {
    return 'Measurement';
  } else if (entity.type in AnalyticType) {
    return 'Analytic';
  } else if (entity.type in AnnotationType) {
    return 'Annotation';
  } else if (entity.type in ModelEditType) {
    return 'Model Edit';
  }
}

export function entityTypePermissions(entityType: EntityType) {
  if (entityType in MeasurementType) {
    return PERMISSIONS.measurements;
  } else if (entityType in AnalyticType) {
    return PERMISSIONS.analytics;
  } else if (entityType in AnnotationType) {
    return PERMISSIONS.annotations;
  } else if (entityType in ModelEditType) {
    return PERMISSIONS.modelEdits;
  }
}

export const GCP_PIN_ID_PREFIX = 'gcp-';
export const ANNOTATION_IMAGE_ID_PREFIX = 'annotation-image-';
export const DESIGN_ENTITY_ID_PREFIX = 'design-';
export const MAP_POSITION_PIN_ID = 'map-position-pin';
export const MY_POSITION_PIN_ID = 'my-position-pin';
export const GO_TO_PIN_ID = 'go-to-pin';
export const UNHOVERABLE_ENTITIES_ID_LIST = [GCP_PIN_ID_PREFIX, DESIGN_ENTITY_ID_PREFIX, MAP_POSITION_PIN_ID, MY_POSITION_PIN_ID];
export const UNSELECTABLE_ENTITIES_ID_LIST = [
  GCP_PIN_ID_PREFIX,
  ANNOTATION_IMAGE_ID_PREFIX,
  DESIGN_ENTITY_ID_PREFIX,
  MAP_POSITION_PIN_ID,
  GO_TO_PIN_ID,
  MY_POSITION_PIN_ID
];

export function generateViewerCredentialsQueryParams(credentials: CloudFrontPreSignedPolicy) {
  return `Policy=${credentials.policy}&Signature=${credentials.signature}&Key-Pair-Id=${credentials.keyPairId}`;
}
