import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const ICONS = [
  'about',
  'account',
  'activities-tab',
  'activity',
  'add-nav',
  'alarm',
  'alarm-full',
  'alphabet-plus',
  'analytics-tab',
  'angle',
  'annotations',
  'annotations-tab',
  'announcement',
  'announcement-full',
  'area',
  'back',
  'cad',
  'calendar',
  'camera',
  'center-focus',
  'chart',
  'chart',
  'check-accuracy',
  'checked',
  'collapse-all',
  'columns',
  'coordinate',
  'create-clone',
  'cross-section',
  'cross-section-progress',
  'crossed-check',
  'cut',
  'cut-fill-base-surface',
  'cut-fill-target-surface',
  'dates',
  'delete-outline',
  'delta-elevation',
  'delta-elevation-base-surface',
  'delta-elevation-overlay-settings',
  'delta-volume',
  'designs-tab',
  'disable-all',
  'do',
  'dont',
  'down-arrow',
  'download',
  'download-full',
  'edit',
  'electric-poles-report',
  'enable-all',
  'error',
  'error-colored-mark',
  'exit-full-screen',
  'expand-all',
  'export',
  'export-csv',
  'export-csv-filter',
  'export-dxf',
  'eye',
  'eye-pinned',
  'file-excel-outline',
  'file-image-outline',
  'file-outline',
  'file-pdf-outline',
  'file-powerpoint-outline',
  'file-video-outline',
  'file-word-outline',
  'fill',
  'filled-circle',
  'filter-site',
  'find-in-page',
  'find-in-page-outline',
  'fit-image',
  'flag',
  'flight-details',
  'full-screen',
  'fullscreen-image',
  'gcp-pin',
  'gcp-pin-ml',
  'gcps-moving-arrow',
  'gcps-moving-double-arrow',
  'gcps-moving-tool',
  'gcps-shift-to-center',
  'gcps-shift-to-edge',
  'generate',
  'geo-referencing',
  'go-to-coordinates',
  'grade-checking-report-offset',
  'hand-point',
  'home',
  'image',
  'images',
  'import',
  'info',
  'info-outline',
  'integration-full',
  'integration-import',
  'line',
  'line-horizontal',
  'link',
  'list',
  'loading-spinner',
  'logoff',
  'logoff-empty',
  'map-diff',
  'map-style-blank-black',
  'map-style-blank-white',
  'map-style-satellite',
  'map-style-street',
  'measure-area',
  'measure-multi-delta-elevation',
  'measure-multi-points',
  'measure-point',
  'measurements-tab',
  'menu',
  'model-edits-tab',
  'my-location',
  'next',
  'north',
  'one-to-one-image',
  'opacity',
  'open-in-new',
  'overlays',
  'overview-tab',
  'overview-tab-outline',
  'pending',
  'person-location',
  'pin',
  'plus',
  'plus-outline',
  'pointer',
  'prev',
  'processing-task',
  'question-in-circle',
  'recalc',
  'report',
  'reports-tab',
  'reset',
  'reset-tilt',
  'reset-view',
  'road',
  'roi',
  'rotate-left',
  'rotate-right',
  'search',
  'settings',
  'settings-full',
  'shape',
  'signpost',
  'site-group',
  'sort-asc',
  'sort-desc',
  'star-circle',
  'support',
  'table',
  'target',
  'target-gcp',
  'task-failure',
  'terms-and-conditions',
  'texture',
  'trash',
  'tutorial-videos',
  'unfilled-circle',
  'units',
  'users',
  'users-add',
  'volume',
  'volume-elevation',
  'warning',
  'warning-colored-mark',
  'weight',
  'zoom-in',
  'zoom-minus',
  'zoom-out',
  'zoom-plus',
  'arrow-right-alt',
  'circle',
  'delete',
  'format_align_center',
  'format_align_left',
  'format_align_right',
  'format_size',
  'format-color-line',
  'format-color-fill',
  'format-color-text',
  'format-line-width',
  'hexagone',
  'polyline',
  'rectangle',
  'hide-label',
  'show-label',
  'sync-camera',
  'flip',
  'report_cross_sec_vol',
  'report_cross_sec_prog',
  'report_vol_grid_heat',
  'report_elev_grid_heat',
  'remove-all-marks',
  'report_surface_grade',
  'report_road_grade'
];

const ICONS_BASE_PATH = '../../../assets/icons/';

@Injectable({ providedIn: 'root' })
export class SvgIconsService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  loadIcons() {
    for (const icon of ICONS) {
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`${ICONS_BASE_PATH}${icon}.svg`));
    }
  }
}
