import { Injectable } from '@angular/core';
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';
import { DEFAULT_GCPS_MOVING_SETTINGS_METER, GCPItem, GCPsMovingSettings, GCPsOffset, Image } from './gcp.model';

export enum GcpProcessState {
  MARKING,
  RESIDUALS
}

export enum LoadingModalTypeEnum {
  GENERATING_HINTS,
  AI_MARKING,
  CALCULATING_ACCURACY,
  AUTO_IMPROVE
}

export interface GcpState {
  loading: boolean;
  isGCPsImagesOverlapping: boolean;
  gcpsOffset: GCPsOffset;
  isGCPsDirty: boolean;
  checkAccuracyCounter: number;
  autoImproveCounter: number;
  ui: {
    loadingModalType: LoadingModalTypeEnum;
    processState: GcpProcessState;
    gcpsMovingSettings: GCPsMovingSettings;
    mapOrthoTaskId: string;
    gcpMap: {
      activeGcpId: string;
      showImages: boolean;
      showGcpName: boolean;
      showImageName: boolean;
      showGcpWithMarking: boolean;
      showGcpWithoutMarking: boolean;
      showCheckpointsWithMarking: boolean;
      showCheckpointsWithoutMarking: boolean;
      showHintCount: boolean;
    };
  };
}

export interface GCPItemsState extends EntityState<GCPItem> {}
export interface ImageState extends EntityState<Image> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'gcp' })
export class GcpStore extends Store<GcpState> {
  gcps = new EntityStore<GCPItemsState>({}, { name: 'gcp-items' });
  images = new EntityStore<ImageState>({}, { name: 'gcp-images' });

  constructor() {
    super({
      loading: false,
      isGCPsImagesOverlapping: true,
      gcpsOffset: { longitude: 0, latitude: 0, degree: 0 },
      isGCPsDirty: false,
      checkAccuracyCounter: 0,
      ui: {
        loadingModalType: null,
        processState: GcpProcessState.MARKING,
        gcpsMovingSettings: DEFAULT_GCPS_MOVING_SETTINGS_METER,
        mapOrthoTaskId: null,
        gcpMap: {
          activeGcpId: null,
          showImages: true,
          showGcpName: true,
          showImageName: false,
          showGcpWithMarking: true,
          showGcpWithoutMarking: true,
          showCheckpointsWithMarking: true,
          showCheckpointsWithoutMarking: true,
          showHintCount: false
        }
      }
    });
  }

  setGcpsMovingSettings(movingSettings: GCPsMovingSettings) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpsMovingSettings = movingSettings;
      })
    );
  }

  setGCPsImagesOverlapping(isOverlap: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.isGCPsImagesOverlapping = isOverlap;
      })
    );
  }

  setGCPsOffset(gcpsOffset: GCPsOffset) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.gcpsOffset = gcpsOffset;
      })
    );
  }

  setLoadingModalType(type: LoadingModalTypeEnum) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.loadingModalType = type;
      })
    );
  }

  toggleShowGcpWithMarking(show: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpMap.showGcpWithMarking = show;
      })
    );
  }

  toggleShowGcpWithoutMarking(show: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpMap.showGcpWithoutMarking = show;
      })
    );
  }

  toggleShowCheckpointsWithMarking(show: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpMap.showCheckpointsWithMarking = show;
      })
    );
  }

  toggleShowCheckpointsWithoutMarking(show: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpMap.showCheckpointsWithoutMarking = show;
      })
    );
  }

  toggleShowHintCount(show: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpMap.showHintCount = show;
      })
    );
  }

  setMapActiveGcpId(id: string) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpMap.activeGcpId = id;
      })
    );
  }

  toggleShowImages(show: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpMap.showImages = show;
      })
    );
  }

  toggleShowGcpName(show: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpMap.showGcpName = show;
      })
    );
  }

  toggleShowImageName(show: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.gcpMap.showImageName = show;
      })
    );
  }

  setImages(images: Image[]) {
    this.images.add(images);
  }

  setProcessState(state: GcpProcessState) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.processState = state;
      })
    );
  }

  setMapOrthoTaskId(taskId: string) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.ui.mapOrthoTaskId = taskId;
      })
    );
  }

  setIsGCPsDirty(isDirty: boolean) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.isGCPsDirty = isDirty;
      })
    );
  }

  setProcessCounters(checkAccuracyCounter = 0, autoImproveCounter = 0) {
    this.update(
      produce((draftState: GcpState) => {
        draftState.checkAccuracyCounter = checkAccuracyCounter;
        draftState.autoImproveCounter = autoImproveCounter;
      })
    );
  }

  resetCheckAccuracyCounter() {
    this.update(
      produce((draftState: GcpState) => {
        draftState.checkAccuracyCounter = 0;
      })
    );
  }

  reset() {
    this.gcps.reset();
    this.images.reset();
    super.reset();
  }
}
